@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
nav {
  color: #705F59;
  font-size: 14px;
  overflow-y: auto;
  display: none;
  /*hidden on mobile*/
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: -100%;
  top: 95px;
  bottom: 15px;
  display: block;
  transition: 0.6s ease-in-out;
  z-index: 10;
}

body.showNav nav {
  transform: translateX(100%);
}

nav h1 {
  color: white;
}

nav .nav-block {
  margin-top:4px;
  overflow: hidden;
  background-color: #FAFAFA;
}

nav h3 {
  margin: 0;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  /*background: url("pattern-brown-mini.png");*/
  background-size: cover;
  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

nav h3 a {
  color: white;
}
nav ul {
  margin: 0;
  padding: 0;
}

nav li {
  padding: 0 10px 0 20px;
  height: 38px;
  line-height: 38px;
  list-style: none;
  margin: 0;
  overflow: hidden;
}
nav li.with-image{
  padding: 0 10px 0 0px;

}

nav h3 .fa {
  float: right;
}

nav .active {
  font-weight: bold;
  color: #6DAB1F;
}

nav .active::before {
  content: "> "
}

nav h3 .active {
  color: inherit;
}

nav h3 .active::before {
  content: ""
}

nav li a {
  color: #705F59;
  white-space: nowrap;
}

nav li a:hover {
  color: #6DAB1F;
}

nav li.with-image a {
  display:flex;
  flex-direction: row;
  align-content: center;
}
nav li.with-image a img{
  height:26px;
  margin:6px;
  flex-grow:0;
  flex-shrink: 0;
}

.badge {
  background-color: #81B54B;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  font-size: 80%;
  display: block;
  color: white;
  line-height: 1.3em;
  padding: 0 0.4em;
  text-align: center
}

nav .badge {
  float: right;
  margin-top: 10px
}

.badge.alert {
  background-color: darkorange
}

.badge.error {
  background-color: darkred
}
.colorpicker {
	position: relative;
	width: 14em;
	height: 13em;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.25em;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.map {
	position: absolute;
	top: 1em;
	bottom: 1em;
	right: 2em;
	left: 1em;
	overflow: hidden;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	border-radius: 0.25em;
}

.map.active {
	cursor: none;
}

.map.dark .pointer {
	border-color: #fff;
}

.map.light .pointer {
	border-color: #000;
}

.map .pointer {
	position: absolute;
	width: 10px;
	height: 10px;
	margin-left: -5px;
	margin-bottom: -5px;
	border-radius: 100%;
	border: 1px solid #000;
	will-change: left, bottom;
}

.map .background {
	top: 0;
	left: 0;
	position: absolute;
	height: 100%;
	width: 100%;
}

.map .background:before,
  .map .background:after {
	display: block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.map .background:after {
	background: linear-gradient(to bottom, transparent 0%, black 100%);
}

.map .background:before {
	background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}

.slider {
	position: absolute;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.slider.vertical {
	top: 0;
	bottom: 0;
	left: 50%;
	width: 10px;
	cursor: ns-resize;
}

.slider.vertical .track {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 8px;
	margin-left: -4px;
}

.slider.horizontal {
	left: 0;
	right: 0;
	top: 50%;
	height: 10px;
	cursor: ew-resize;
}

.slider.horizontal .track {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	height: 8px;
	margin-top: -4px;
}

.slider .track {
	border-radius: 3px;
	background: #888;
}

.slider .pointer {
	position: absolute;
	bottom: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin-left: -8px;
	margin-bottom: -8px;
	border-radius: 16px;
	background: #fff;
	box-shadow: inset 0 0 0 1px #ccc,0 1px 2px #ccc;
	will-change: left, bottom;
}

.hue-slider {
	position: absolute;
	top: 1em;
	bottom: 1em;
	right: 1.3em;
}

.hue-slider .track {
	background: linear-gradient(to bottom, #FF0000 0%, #FF0099 10%, #CD00FF 20%, #3200FF 30%, #0066FF 40%, #00FFFD 50%, #00FF66 60%, #35FF00 70%, #CDFF00 80%, #FF9900 90%, #FF0000 100%);
}

.opacity-slider {
	position: absolute;
	bottom: 1.3em;
	left: 1em;
	right: 2em;
}

.opacity-slider .slider {
	background: #fff url("data:image/gif;base64,R0lGODdhEAAQAPEAAMvLy8zMzP///wAAACwAAAAAEAAQAEACHYxvosstCAEMrq6Jj812Y59NIDQipdY5XLWqH4sVADs=") repeat;
	background-size: 8px 8px;
	height: 8px;
}

.opacity-slider .slider .track {
	height: 8px;
	margin-top: -4px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #FFF 100%);
}

.with-opacity-slider {
	height: 14em;
}

.with-opacity-slider .map {
	bottom: 2em;
}

.with-opacity-slider .hue-slider {
	bottom: 2em;
}
table {
  width: 100%
}

i.glyphicon {
  display: none;
}

.btn-add::after {
  content: '+';
}

.array-item-move-up::after {
  content: '↑';
}

.array-item-move-down::after {
  content: '↓';
}

.array-item-remove::after {
  content: '-';
}

fieldset {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  width: 100%
}
#content  form{
  margin-top:0.5em;
  margin-bottom:2em;
}

.form-group{
  margin:0 0 0.5em 0
}
.form-group legend {
  margin:0
}


#content .form-group legend,
#content .form-group fieldset {
  border: none;
}

#content .form-control{
  border-top:none;
  border-left:none;
  border-right:none;
  border-radius:0;
  box-shadow:none;
}

label.control-label {
  font-size: 16px;
  font-weight: normal;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list {
  overflow-x: auto;
  display: flex;
  flex-direction: row
}
form>.form-group>fieldset>.array-item-list {
  overflow-x: auto;
  display: flex;
  flex-direction: row
}

form [type=submit] {
  position: fixed;
  bottom: 0.25em;
  width: auto;
  padding-left: 3em;
  padding-right: 3em;
  right: 0.25em;
  text-align: center;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item {
  flex-shrink: 0;
  max-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin: 0.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

form>.form-group>fieldset>.array-item-list>.array-item {
  flex-shrink: 0;
  max-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin: 0.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.col-xs-9 {
  float: none;
  width: 100%;
  margin-top: 3em;
}
form>.form-group>fieldset>.array-item-list>.array-item>.col-xs-9 {
  float: none;
  width: 100%;
  margin-top: 3em;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.col-xs-3 {
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 120px;
}
form>.form-group>fieldset>.array-item-list>.array-item>.col-xs-3 {
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 120px;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.btn-group {
  position: absolute;
  top: 1em;
  right: 0;
  width: 100%;
}
form>.form-group>fieldset>.array-item-list>.array-item>.btn-group {
  position: absolute;
  top: 1em;
  right: 0;
  width: 100%;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list .array-item-move-up::after {
  content: '⬅'
}
form>.form-group>fieldset>.array-item-list .array-item-move-up::after {
  content: '⬅'
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list .array-item-move-down::after {
  content: '➡'
}
form>.form-group>fieldset>.array-item-list .array-item-move-down::after {
  content: '➡'
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item .array-item-remove::after {
  content: 'x'
}
form>.form-group>fieldset>.array-item-list>.array-item .array-item-remove::after {
  content: 'x'
}

form .form-group.inline  {
  display:flex
}
form .form-group.inline label {
  flex:3 1
}
form .form-group.inline input ,form .form-group.inline select {
  flex:2 1;
}
input[type="number"]{
  text-align:right
}


.event-impact{
  transform-origin: center;
  border-radius: 50%;
  width:40px;
  height:40px;
  box-shadow: 
  5px 5px 10px red, 
  -5px -5px 10px red, 
  5px -5px 10px red, 
  -5px 5px 10px red,
  inset 5px 5px 10px red, 
  inset -5px -5px 10px red, 
  inset 5px -5px 10px red, 
  inset -5px 5px 10px red;
  border:1px solid red;
  -webkit-animation: event-appear 4s ease-in-out infinite ;
          animation: event-appear 4s ease-in-out infinite ;
  opacity:0;
}
@-webkit-keyframes event-appear {
  0% {
      opacity: 1.0;
      transform: scale(0.01);

  box-shadow: 
  5px 5px 10px blue, 
  -5px -5px 10px blue, 
  5px -5px 10px blue, 
  -5px 5px 10px blue,
  inset 5px 5px 10px blue, 
  inset -5px -5px 10px blue, 
  inset 5px -5px 10px blue, 
  inset -5px 5px 10px blue;

  border-color:blue;
  }
  100% {
    opacity: 0;
    transform: scale(3.0);
    box-shadow: 
    5px 5px 10px red, 
    -5px -5px 10px red, 
    5px -5px 10px red, 
    -5px 5px 10px red,
    inset 5px 5px 10px red, 
    inset -5px -5px 10px red, 
    inset 5px -5px 10px red, 
    inset -5px 5px 10px red;
    border-color:red;
  }
}
@keyframes event-appear {
  0% {
      opacity: 1.0;
      transform: scale(0.01);

  box-shadow: 
  5px 5px 10px blue, 
  -5px -5px 10px blue, 
  5px -5px 10px blue, 
  -5px 5px 10px blue,
  inset 5px 5px 10px blue, 
  inset -5px -5px 10px blue, 
  inset 5px -5px 10px blue, 
  inset -5px 5px 10px blue;

  border-color:blue;
  }
  100% {
    opacity: 0;
    transform: scale(3.0);
    box-shadow: 
    5px 5px 10px red, 
    -5px -5px 10px red, 
    5px -5px 10px red, 
    -5px 5px 10px red,
    inset 5px 5px 10px red, 
    inset -5px -5px 10px red, 
    inset 5px -5px 10px red, 
    inset -5px 5px 10px red;
    border-color:red;
  }
}

.form-group.field-array.characters .variant {
  font-size:60%;
}

.book h2{
  margin-top:1em;
  margin-bottom:0.25em;
}
.book h3 {
  margin: 0.5em 0;
}
.book .internal{
  color:#888;
}
.book .experience .characters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.book .experience .theme {
  padding-right: 1em;
  margin-right: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.book audio{
  width:100%;
  height:25px;
  margin:0.5em 0;
  border-radius:5px;
}


.book .resource-image{
  display: inline-block;
  max-width: 100%;
  position: relative
}

/*from https://la-cascade.io/les-motifs-css3/ */

.book .resource-image.background-mesh{
  background-color: 'transparent';
  background-image:
    linear-gradient(45deg, rgba(0,0,0,0.15) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.15)  75%), linear-gradient(45deg, rgba(0,0,0,0.15)  25%, transparent 25%, transparent 75%, rgba(0,0,0,0.15)  75%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px
}
.book .resource-image .resource-image-title{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #FFFFFFCC;
}

.book article {
  background-color:rgba(128, 106, 100, 0.05);
  padding:10px;
  margin:10px;
  border-radius:5px;
}
.book section {
  display: flex;
  flex-direction: row;
}

.book section>div{
  flex:4 1;
}

.book section>aside.common {
  flex: 1 1;
  border-right: 1px solid lightgray;
  padding-right: 1em;
  margin-right: 1em;
}
.book .module section>aside.common {
  flex: 1 1;
}

.book section>aside.diagnostic{
  flex: 1 1;
  border-left: 1px solid lightgray;
  padding-left: 1em;
  margin-left: 1em;
}
.book .module section>aside.diagnostic{
  flex: 0 0 190px;
}

.book .icons-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.book ul {
  list-style-type: none;
  padding:0;
}
.book li {
  margin:0 0 0 0.5em;
}

.book a{
 color: #705F59;
}
.book h1 a,
.book h2 a
.book h3 a
.book h4 a{
  padding:0 0.5em;
  display: inline-block;
}

.book .booktext{
  padding:0.5em;
  margin:0.5em 0;
  border-radius: 0.5em;
  background-color: rgba(255,255,255,0.5);
  transition: all 0.5s ease;
  position:relative;
}

.book .booktext:hover{
  background-color: rgba(255,255,255,0.8);
}
.book .booktext .edit{
  position: absolute;
  display:block;
  right:0.25em;
  top:0.25em;
  opacity:0;
  transition: all 0.5s ease;
  background-color:white;
  color:rgb(128, 106, 100);
  padding:0.25em;
  border-radius: 0.25em;

}
.book .booktext:hover .edit{
  opacity:1.0;
}

.book .booktext li{
  margin-left:1em;
  list-style-type: circle;
}

.book .dialog {
  display:flex;
  flex-direction:row;
  margin: 0.1em;
}
.book .dialog > .dialog-images {
  flex: 1 1;
  position:relative;
}
.book .dialog > .dialog-text {
  flex: 2 1;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em;
}

.book .dialog-animate-icon-left {
  position:absolute;
  bottom:5%;
  left:5%;
  background-color: rgba(255,255,255,0.5);
  width: 1em;
  height:1em;
  text-align: center;
  border-radius: 50%;
}
.book .dialog-animate-icon-right {
  position:absolute;
  bottom:5%;
  right:2em;
  background-color: rgba(255,255,255,0.5);
  width:1em;
  height:1em;
  text-align: center;
  border-radius: 50%;
}

@media print {
  @page {
    marks: crop;
    size: A4 portrait;
    margin: 2cm 3cm;
  }
  html, body {
    width: 210mm;
    height: 297mm;  
  }
  body {
    margin:2cm;
  }
  nav, h1 > a, h3 > a, h2 >a, h4 > a, header,aside.diagnostic, audio, video, .usedBy,.book .booktext .edit {
    display:none!important;
  }.module.conversation aside.common, .book .module.score_conversation aside.common {
    display:none;
  }
  .book section>aside.common, .book .module section>aside.common {
    flex:1 1;
    padding: 0.1em;
  }
  .book section>div {
    flex:3 1
  }
  .book article {
    padding:0.1em;
    margin:0.1em;
  }
  .book .experience .theme {
    padding-right: 0.1em;
    margin-right: 0.1em; 
  }
  /*
  .book article {
    display:none
  }
  .book article.scenario.conversation , .book article.experience {
    display: block;
    page-break-before: always;
  }
  .book article.scenario article.module {
    display:none
  }
  .book article.scenario article.module.conversation {
    display:block
  }*/
  .book article {
    page-break-inside: avoid;
  }
  .book .dialog {
    page-break-inside: avoid;
  }
  .book .theme , .book .characters{
    page-break-inside: avoid;
  }

  .book .dialog > .dialog-text {
    flex: 4 1;
  }

  .book .resource-image .resource-image-title{
    position: static;
    position: initial;
    text-align: center;
  }
  .book .resource-image {
    margin-bottom:0.5em;
  }
}

.book  .modified .highlighted {
  border-width: 3px;
  border-color: rgb(0, 174, 255);
  background-color: rgb(0, 174, 255, 0.2);
  border-style: dotted;
}
.book  .deleted {
  border-width: 3px;
  border-color: rgb(116, 9, 26);
  background-color: rgb(116, 9, 26, 0.2);
  border-style: dotted;
}
.book  .created {
  border-width: 3px;
  border-color: rgb(4, 126, 4);
  background-color: rgb(4, 126, 4, 0.2);
  border-style: dotted;
}
html,
body {
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

video {
  max-width: 100%
}

strong {
  font-weight: 700;
  color: #333;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Raleway', sans-serif;
  background-color: #FAFAFA;
  /*background: url("pattern.png");*/
  background-size: cover;
}

* {
  box-sizing: border-box
}

#app>div {
  flex: 1 1;
  display: flex;
  flex-direction: row;
}


header {
  flex:0 0 100px;
  display: flex;
  flex-direction: row;
  background-color: #6DAB1F;
  /*background: url("pattern-mini.png");*/
  background: rgba(98, 195, 68, 1);
  background: linear-gradient(135deg, rgba(98, 195, 68, 1) 0%, rgba(178, 232, 123, 1) 54%, rgba(178, 232, 123, 1) 100%);
  /*      background-size:cover;*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;
  padding-top: 40px;
}

body.beta header {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
background: #a90329; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.localhost header {/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#499bea+0,207ce5+100;Blue+3d+%237 */
  background: #499bea; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #499bea 0%,#207ce5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

header>.brand {
  color: white;
  width: 60px;
  background: url(/static/media/logo.2731fbe1.jpg);
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat
}

header>.path {
  color: white;
  padding: 10px;
  flex: 4 1;
  font-size: 120%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

header>.path .active {
  font-size: 150%
}

header>.path a {
  color: white;
}

header>.path a:before {
  content: '>';
  padding: 0 0.5em;
}

header>.path a.home:before {
  content: '';
  padding: 0;
}

header>.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 1;
}

header button {
  max-width: 60px;
  flex: 1 1;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  opacity: 0.8;
}

header>.tools a {
  max-width: 60px;
  flex: 1 1;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  opacity: 0.8;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin-right:0.25em;
}

header>.tools button:hover,header>.tools a:hover {
  color: white;
  opacity: 1;
}

header>.tools {
  width: 80px;
}

header .search {
  position: relative;
  margin: 10px
}

header .search .fa {
  position: absolute;
  right: 15px;
  line-height: 30px;
  color: #FAFAFA;
}

header .search input {
  margin: 0;
  line-height: 35px;
  height: 35px;
  font-size: 25px;
  display: block;
  border: none;
  width: 200px;
  background-color: transparent;
  color: #FAFAFA;
  border-bottom: 1px solid #FAFAFA;
}

header .search input::-webkit-input-placeholder {
  color: #FAFAFA;
}

header .search input::-ms-input-placeholder {
  color: #FAFAFA;
}

header .search input::placeholder {
  color: #FAFAFA;
}

body.showNav #buttonToggleLeftNav {
  color: #6DAB1F;
  background-color: #FAFAFA;
}

footer {
  height: 40px;
}

#app>#notifications-center {
  display: block;
  position: absolute;
  min-width: 250px;
  right: 1em;
  bottom: 0;
  top: 1em;
  z-index: 15;
  pointer-events: none;
}

#notifications-center>* {
  pointer-events: all;
  width: 100%;
  height: auto;
  margin: 1em 0;
  padding: 1em;
}

#notifications-center>.deleting {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.sites-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
}

.site-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
}

.site-preview h3 {
  margin: 0 0 0.5em 0;
  text-overflow: clip ellipsis;
  height: 24px;
  overflow: hidden;
  padding: 0.25em 0.5em;
  font-size: 16px;

  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

.site-preview>div {
  flex: 1 1;
}

.site-preview h4 {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  text-overflow: clip ellipsis;
  height: 18px;
  overflow: hidden;
  color: white;
  padding: 0.25em 0.5em;
  font-size: 12px;
}

.experience-preview-container {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
}

.experience-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

.experience-preview img {
  width: 100%;
  aspect-ratio: 2.5;
  object-fit: cover;
}

.experience-preview h3 {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: rgba(128, 106, 100, 1);
  padding: 0 0.5em;
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
  font-size: 16px;
}

.applications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
}

.application-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
}

.application-preview h3 {
  margin: 0 0 0.5em 0;
  padding: 0.25em 0.5em;
  font-size: 16px;

  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

.application-preview>div {
  flex: 1 1;
}

.application-preview h4 {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  text-overflow: clip ellipsis;
  height: 18px;
  overflow: hidden;
  color: white;
  padding: 0.25em 0.5em;
  font-size: 12px;
}

aside {
  flex: 1 1;
}



#content {
  flex: 3 1;
  overflow-y: auto;
  background-color: #FAFAFA;
  padding: 0.5em 1em;;
  overflow-x: hidden;
} 

#app-preview {
  display: none;
}

h1,
h2 {
  margin: 0
}

p.bg-danger, p.bg-warning,p.bg-success,p.bg-info{
  padding:1em;
  border-radius: 4px;
}
.row-flex {
  display: flex;
  flex-direction: "row"
}



/*modal*/

/*animation*/

.slideInFromBottom-enter {
  transform: translateY(100%);
}

.slideInFromBottom-enter.slideInFromBottom-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}

.slideInFromBottom-leave {
  transform: translateY(0);
}

.slideInFromBottom-leave.slideInFromBottom-leave-active {
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;
  ;
}

.opacity-enter {
  opacity: 0;
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.opacity-leave {
  opacity: 1;
}

.opacity-leave.opacity-leave-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  ;
}


#content.statistics h3 {
  text-align: center
}
.btn-default {
  border-width: 0;
}
.btn-warning{
  color:#eea236;
  background-color:white;
}
.btn-danger{
  color:#d43f3a;
  background-color:white;
}
.btn-info{
  color:#5bc0de;
  background-color:white;
}
.btn-group > .btn {
  border-width:1px;
}

@media screen and (min-width:640px) {

  .site-preview {
    flex: 0 0 300px;
    margin: 0.5em;
  }

  .experience-preview {
    flex: 0 0 300px;
    margin: 0.5em;
  }
  .application-preview {
    flex: 0 0 170px;
    margin: 0.5em;
  }

  nav {
    display: block;
    width: 250px;
    position: relative;
    left: 0;
    top: auto;
    bottom: auto;
    background-color: #FAFAFA;
  }

  header {
    flex: 0 0 60px;
    padding-top: 0;
  }

  header>.path {
    padding: 20px;
    display: block;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
  }

  #buttonToggleLeftNav {
    display: none
  }
}

