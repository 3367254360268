table {
  width: 100%
}

i.glyphicon {
  display: none;
}

.btn-add::after {
  content: '+';
}

.array-item-move-up::after {
  content: '↑';
}

.array-item-move-down::after {
  content: '↓';
}

.array-item-remove::after {
  content: '-';
}

fieldset {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  width: 100%
}
#content  form{
  margin-top:0.5em;
  margin-bottom:2em;
}

.form-group{
  margin:0 0 0.5em 0
}
.form-group legend {
  margin:0
}


#content .form-group legend,
#content .form-group fieldset {
  border: none;
}

#content .form-control{
  border-top:none;
  border-left:none;
  border-right:none;
  border-radius:0;
  box-shadow:none;
}

label.control-label {
  font-size: 16px;
  font-weight: normal;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list {
  overflow-x: auto;
  display: flex;
  flex-direction: row
}
form>.form-group>fieldset>.array-item-list {
  overflow-x: auto;
  display: flex;
  flex-direction: row
}

form [type=submit] {
  position: fixed;
  bottom: 0.25em;
  width: auto;
  padding-left: 3em;
  padding-right: 3em;
  right: 0.25em;
  text-align: center;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item {
  flex-shrink: 0;
  max-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin: 0.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

form>.form-group>fieldset>.array-item-list>.array-item {
  flex-shrink: 0;
  max-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin: 0.5em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.col-xs-9 {
  float: none;
  width: 100%;
  margin-top: 3em;
}
form>.form-group>fieldset>.array-item-list>.array-item>.col-xs-9 {
  float: none;
  width: 100%;
  margin-top: 3em;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.col-xs-3 {
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 120px;
}
form>.form-group>fieldset>.array-item-list>.array-item>.col-xs-3 {
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 120px;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item>.btn-group {
  position: absolute;
  top: 1em;
  right: 0;
  width: 100%;
}
form>.form-group>fieldset>.array-item-list>.array-item>.btn-group {
  position: absolute;
  top: 1em;
  right: 0;
  width: 100%;
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list .array-item-move-up::after {
  content: '⬅'
}
form>.form-group>fieldset>.array-item-list .array-item-move-up::after {
  content: '⬅'
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list .array-item-move-down::after {
  content: '➡'
}
form>.form-group>fieldset>.array-item-list .array-item-move-down::after {
  content: '➡'
}

form>.form-group>fieldset>.form-group>fieldset>.array-item-list>.array-item .array-item-remove::after {
  content: 'x'
}
form>.form-group>fieldset>.array-item-list>.array-item .array-item-remove::after {
  content: 'x'
}

form .form-group.inline  {
  display:flex
}
form .form-group.inline label {
  flex:3
}
form .form-group.inline input ,form .form-group.inline select {
  flex:2;
}
input[type="number"]{
  text-align:right
}


.event-impact{
  transform-origin: center;
  border-radius: 50%;
  width:40px;
  height:40px;
  box-shadow: 
  5px 5px 10px red, 
  -5px -5px 10px red, 
  5px -5px 10px red, 
  -5px 5px 10px red,
  inset 5px 5px 10px red, 
  inset -5px -5px 10px red, 
  inset 5px -5px 10px red, 
  inset -5px 5px 10px red;
  border:1px solid red;
  animation: event-appear 4s ease-in-out infinite ;
  opacity:0;
}
@keyframes event-appear {
  0% {
      opacity: 1.0;
      transform: scale(0.01);

  box-shadow: 
  5px 5px 10px blue, 
  -5px -5px 10px blue, 
  5px -5px 10px blue, 
  -5px 5px 10px blue,
  inset 5px 5px 10px blue, 
  inset -5px -5px 10px blue, 
  inset 5px -5px 10px blue, 
  inset -5px 5px 10px blue;

  border-color:blue;
  }
  100% {
    opacity: 0;
    transform: scale(3.0);
    box-shadow: 
    5px 5px 10px red, 
    -5px -5px 10px red, 
    5px -5px 10px red, 
    -5px 5px 10px red,
    inset 5px 5px 10px red, 
    inset -5px -5px 10px red, 
    inset 5px -5px 10px red, 
    inset -5px 5px 10px red;
    border-color:red;
  }
}

.form-group.field-array.characters .variant {
  font-size:60%;
}
