nav {
  color: #705F59;
  font-size: 14px;
  overflow-y: auto;
  display: none;
  /*hidden on mobile*/
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: -100%;
  top: 95px;
  bottom: 15px;
  display: block;
  transition: 0.6s ease-in-out;
  z-index: 10;
}

body.showNav nav {
  transform: translateX(100%);
}

nav h1 {
  color: white;
}

nav .nav-block {
  margin-top:4px;
  overflow: hidden;
  background-color: #FAFAFA;
}

nav h3 {
  margin: 0;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  /*background: url("pattern-brown-mini.png");*/
  background-size: cover;
  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

nav h3 a {
  color: white;
}
nav ul {
  margin: 0;
  padding: 0;
}

nav li {
  padding: 0 10px 0 20px;
  height: 38px;
  line-height: 38px;
  list-style: none;
  margin: 0;
  overflow: hidden;
}
nav li.with-image{
  padding: 0 10px 0 0px;

}

nav h3 .fa {
  float: right;
}

nav .active {
  font-weight: bold;
  color: #6DAB1F;
}

nav .active::before {
  content: "> "
}

nav h3 .active {
  color: inherit;
}

nav h3 .active::before {
  content: ""
}

nav li a {
  color: #705F59;
  white-space: nowrap;
}

nav li a:hover {
  color: #6DAB1F;
}

nav li.with-image a {
  display:flex;
  flex-direction: row;
  align-content: center;
}
nav li.with-image a img{
  height:26px;
  margin:6px;
  flex-grow:0;
  flex-shrink: 0;
}

.badge {
  background-color: #81B54B;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  font-size: 80%;
  display: block;
  color: white;
  line-height: 1.3em;
  padding: 0 0.4em;
  text-align: center
}

nav .badge {
  float: right;
  margin-top: 10px
}

.badge.alert {
  background-color: darkorange
}

.badge.error {
  background-color: darkred
}