.book h2{
  margin-top:1em;
  margin-bottom:0.25em;
}
.book h3 {
  margin: 0.5em 0;
}
.book .internal{
  color:#888;
}
.book .experience .characters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.book .experience .theme {
  padding-right: 1em;
  margin-right: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.book audio{
  width:100%;
  height:25px;
  margin:0.5em 0;
  border-radius:5px;
}


.book .resource-image{
  display: inline-block;
  max-width: 100%;
  position: relative
}

/*from https://la-cascade.io/les-motifs-css3/ */

.book .resource-image.background-mesh{
  background-color: 'transparent';
  background-image:
    linear-gradient(45deg, rgba(0,0,0,0.15) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.15)  75%), linear-gradient(45deg, rgba(0,0,0,0.15)  25%, transparent 25%, transparent 75%, rgba(0,0,0,0.15)  75%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px
}
.book .resource-image .resource-image-title{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #FFFFFFCC;
}

.book article {
  background-color:rgba(128, 106, 100, 0.05);
  padding:10px;
  margin:10px;
  border-radius:5px;
}
.book section {
  display: flex;
  flex-direction: row;
}

.book section>div{
  flex:4;
}

.book section>aside.common {
  flex: 1;
  border-right: 1px solid lightgray;
  padding-right: 1em;
  margin-right: 1em;
}
.book .module section>aside.common {
  flex: 1;
}

.book section>aside.diagnostic{
  flex: 1;
  border-left: 1px solid lightgray;
  padding-left: 1em;
  margin-left: 1em;
}
.book .module section>aside.diagnostic{
  flex: 0 0 190px;
}

.book .icons-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.book ul {
  list-style-type: none;
  padding:0;
}
.book li {
  margin:0 0 0 0.5em;
}

.book a{
 color: #705F59;
}
.book h1 a,
.book h2 a
.book h3 a
.book h4 a{
  padding:0 0.5em;
  display: inline-block;
}

.book .booktext{
  padding:0.5em;
  margin:0.5em 0;
  border-radius: 0.5em;
  background-color: rgba(255,255,255,0.5);
  transition: all 0.5s ease;
  position:relative;
}

.book .booktext:hover{
  background-color: rgba(255,255,255,0.8);
}
.book .booktext .edit{
  position: absolute;
  display:block;
  right:0.25em;
  top:0.25em;
  opacity:0;
  transition: all 0.5s ease;
  background-color:white;
  color:rgb(128, 106, 100);
  padding:0.25em;
  border-radius: 0.25em;

}
.book .booktext:hover .edit{
  opacity:1.0;
}

.book .booktext li{
  margin-left:1em;
  list-style-type: circle;
}

.book .dialog {
  display:flex;
  flex-direction:row;
  margin: 0.1em;
}
.book .dialog > .dialog-images {
  flex: 1;
  position:relative;
}
.book .dialog > .dialog-text {
  flex: 2;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em;
}

.book .dialog-animate-icon-left {
  position:absolute;
  bottom:5%;
  left:5%;
  background-color: rgba(255,255,255,0.5);
  width: 1em;
  height:1em;
  text-align: center;
  border-radius: 50%;
}
.book .dialog-animate-icon-right {
  position:absolute;
  bottom:5%;
  right:2em;
  background-color: rgba(255,255,255,0.5);
  width:1em;
  height:1em;
  text-align: center;
  border-radius: 50%;
}

@media print {
  @page {
    marks: crop;
    size: A4 portrait;
    margin: 2cm 3cm;
  }
  html, body {
    width: 210mm;
    height: 297mm;  
  }
  body {
    margin:2cm;
  }
  nav, h1 > a, h3 > a, h2 >a, h4 > a, header,aside.diagnostic, audio, video, .usedBy,.book .booktext .edit {
    display:none!important;
  }.module.conversation aside.common, .book .module.score_conversation aside.common {
    display:none;
  }
  .book section>aside.common, .book .module section>aside.common {
    flex:1;
    padding: 0.1em;
  }
  .book section>div {
    flex:3
  }
  .book article {
    padding:0.1em;
    margin:0.1em;
  }
  .book .experience .theme {
    padding-right: 0.1em;
    margin-right: 0.1em; 
  }
  /*
  .book article {
    display:none
  }
  .book article.scenario.conversation , .book article.experience {
    display: block;
    page-break-before: always;
  }
  .book article.scenario article.module {
    display:none
  }
  .book article.scenario article.module.conversation {
    display:block
  }*/
  .book article {
    page-break-inside: avoid;
  }
  .book .dialog {
    page-break-inside: avoid;
  }
  .book .theme , .book .characters{
    page-break-inside: avoid;
  }

  .book .dialog > .dialog-text {
    flex: 4;
  }

  .book .resource-image .resource-image-title{
    position: initial;
    text-align: center;
  }
  .book .resource-image {
    margin-bottom:0.5em;
  }
}

.book  .modified .highlighted {
  border-width: 3px;
  border-color: rgb(0, 174, 255);
  background-color: rgb(0, 174, 255, 0.2);
  border-style: dotted;
}
.book  .deleted {
  border-width: 3px;
  border-color: rgb(116, 9, 26);
  background-color: rgb(116, 9, 26, 0.2);
  border-style: dotted;
}
.book  .created {
  border-width: 3px;
  border-color: rgb(4, 126, 4);
  background-color: rgb(4, 126, 4, 0.2);
  border-style: dotted;
}